<template>
  <a-form :form="form" :label-col="{ span: 2 }" :wrapper-col="{ span: 4 }" @submit="handleSubmit">
    <a-form-item label="Time Value">
      <a-input type="number" min="1"
        v-decorator="['time_value', { rules: [{ required: true, message: 'Please input your note!' }] }]"
      />
    </a-form-item>
    <a-form-item label="Time Unit">
      <a-select
        v-decorator="[
          'time_unit',
          { rules: [{ required: true, message: 'Please select your gender!' }] },
        ]"
        placeholder="Select a option and change input text above"
        @change="handleSelectChange"
      >
        <a-select-option value="day" selected="true">
          Day
        </a-select-option>
        <a-select-option value="week">
          Week
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
      <a-button type="primary" html-type="submit">
        Submit
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
export default {
  data() {
    return {
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
    }
  },
  mounted() {
    this.form.setFieldsValue({
      time_unit: 'week',
      time_value: 1,
    })
  },
  methods: {
    handleSubmit(e) {
      const vm = this
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          localStorage.setItem('wysiwyg-popularpost', JSON.stringify(values))
        }
        vm.$bvModal.hide('wsywyg-popularpost')
      })
    },
    handleSelectChange(value) {
      const formValue = this.form.getFieldsValue(['time_unit', 'time_value'])
      console.log(formValue)
    },
  },
}
</script>
