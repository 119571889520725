import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import List from '@ckeditor/ckeditor5-list/src/list'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation'
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount'

import Image from '@ckeditor/ckeditor5-easy-image/src/easyimage'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed'
import Latest3Post from '../ckplugins/Latest3Post'
import { InsertImage } from '../ckplugins/InsertImage'
import PopularPost from '../ckplugins/PopularPost'
// import { EmbedPlugin } from '../ckplugins/EmbedPlugin'
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed'
// import RelatedPost from '../ckplugins/RelatedPost'

export const CKConfig = (state) => {
  return {
    plugins: [
      EssentialsPlugin,
      BoldPlugin,
      ItalicPlugin,
      LinkPlugin,
      ParagraphPlugin,
      Heading,
      List,
      Indent,
      Image,
      ImageToolbar,
      ImageStyle,
      MediaEmbed,
      ImageCaption,
      InsertImage,
      Table,
      TableToolbar,
      BlockQuote,
      PasteFromOffice,
      TextTransformation,
      WordCount,
      Latest3Post,
      PopularPost,
      HtmlEmbed,
      // RelatedPost,
    ],
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'insertMediaToPost',
        'blockQuote',
        'insertTable',
        '|',
        'undo',
        'redo',
        'mediaEmbed',
        'code',
        'insertLatest3Post',
        'insertPopularPost',
        'htmlEmbed',
        // 'insertRelatedPost',
      ],
    },
    image: {
      toolbar: [
        'imageStyle:alignLeft',
        'imageStyle:alignCenter',
        'imageStyle:alignRight',
        '|',
        'imageStyle:full',
        'imageStyle:side',
        '|',
        'imageTextAlternative',
      ],
      styles: ['full', 'side', 'alignLeft', 'alignCenter', 'alignRight'],
    },
    link: {
      addTargetToExternalLinks: true,
      decorators: [
        {
          mode: 'manual',
          label: 'New tab',
        },
      ],
    },
    heading: {
      options: [
        {
          model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph',
        },
        {
          model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2',
        },
        {
          model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3',
        },
        {
          model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4',
        },
        {
          model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5',
        },
        {
          model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6',
        },
      ],
    },
    wordCount: {
      onUpdate: (stats) => {
        state.words = stats.words
        state.characters = stats.characters
      },
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
    },
  }
}
