import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import app from '@/main'
// import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'
import DropdownPanelView from '@ckeditor/ckeditor5-ui/src/dropdown/dropdownpanelview'
import DropdownView from '@ckeditor/ckeditor5-ui/src/dropdown/dropdownview'
import DropdownButtonView from '@ckeditor/ckeditor5-ui/src/dropdown/button/dropdownbuttonview'
import { addListToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils'
import Collection from '@ckeditor/ckeditor5-utils/src/collection'
import Model from '@ckeditor/ckeditor5-ui/src/model'
import ListItemView from '@ckeditor/ckeditor5-ui/src/list/listitemview'
export default class Latest3Post extends Plugin {
  init() {
    const editor = this.editor
    let relatedData = []
    editor.ui.componentFactory.add('insertLatest3Post', (locale) => {
      const button = new DropdownButtonView(locale)
      const panel = new DropdownPanelView(locale)
      const dropdown = new DropdownView(locale, button, panel)
      dropdown.buttonView.set({
        label: 'Latest Post',
        withText: true,
      })
      const total = Array.from({ length: 10 }, (_, i) => i + 1)
      const items = new Collection()
      total.map((m, i) => {
        const list = new ListItemView(locale)
        list.set({
          type: 'button',
          model: new Model({
            count: m,
            id: `${m}-list-post`,
            withText: true,
            label: `${m} Latest posts`,
          }),
        })
        items.add(list)
      })

      addListToDropdown(dropdown, items)

      dropdown.on('execute', async(e) => {
        dropdown.isOpen = false
        const source = e.source
        app.$store.dispatch('post/PUBLIC_FETCH', {
          page: 1,
          perPage: source.count,
          portal_id: app.$store.state.settings.activePortal,
        }).then(res => {
          relatedData = res.data.data
          const elementData = relatedData.map(m => `<li><a href="${m.url}">${m.title}</a></li>`).join('')
          const content = `<ul class="wp-block-latest-posts__list wp-block-latest-posts">
            ${elementData}
          </ul>`
          const viewFragment = editor.data.processor.toView(content)
          const modelFragment = editor.data.toModel(viewFragment)
          editor.model.insertContent(modelFragment)
        })
      })

      return dropdown
    })
  }
}
