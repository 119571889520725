import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import app from '@/main'
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'

export class InsertImage extends Plugin {
  init() {
    const editor = this.editor
    editor.ui.componentFactory.add('insertMediaToPost', (locale) => {
      const view = new ButtonView(locale)
      view.set({
        label: 'Insert image',
        icon: imageIcon,
        tooltip: true,
      })
      view.on('execute', () => {
        app.$bvModal.show('post-media-finder')
        app.$store.dispatch('media/FETCH')
      })
      return view
    })
  }
}
