import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import app from '@/main'
// import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'
import DropdownPanelView from '@ckeditor/ckeditor5-ui/src/dropdown/dropdownpanelview'
import DropdownView from '@ckeditor/ckeditor5-ui/src/dropdown/dropdownview'
import DropdownButtonView from '@ckeditor/ckeditor5-ui/src/dropdown/button/dropdownbuttonview'
import { addListToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils'
import Collection from '@ckeditor/ckeditor5-utils/src/collection'
import Model from '@ckeditor/ckeditor5-ui/src/model'
import ListItemView from '@ckeditor/ckeditor5-ui/src/list/listitemview'
import store from 'store'
export default class PopularPost extends Plugin {
  init() {
    const editor = this.editor
    let relatedData = []
    editor.ui.componentFactory.add('insertPopularPost', (locale) => {
      const button = new DropdownButtonView(locale)
      const panel = new DropdownPanelView(locale)
      const dropdown = new DropdownView(locale, button, panel)
      dropdown.buttonView.set({
        label: 'Popular Posts',
        withText: true,
      })
      const total = [3]
      const items = new Collection()
      total.map((m, i) => {
        const list = new ListItemView(locale)
        list.set({
          type: 'button',
          model: new Model({
            count: 3,
            id: `${m}-popular-post`,
            withText: true,
            label: `${m} Popular posts`,
          }),
        })
        items.add(list)
      })

      addListToDropdown(dropdown, items)

      let num = 0
      const execute = (num) => {
        app.$root.$on('bv::hide::modal', (bvEvent) => {
          if (bvEvent === 'wsywyg-popularpost') {
            const param = store.get('wysiwyg-popularpost')
            const params = {
              ...param,
              portal_id: app.$store.state.settings.activePortal,
            }
            if (num === 0) {
              app.$store.dispatch('post/TRENDING_FETCH', params).then(res => {
                relatedData = res.data
                const elementData = relatedData.slice(0, 3).map(m => `<li><a href="${m.url}">${m.title}</a></li>`).join('')
                const content = `<ul class="wp-block-latest-posts__list wp-block-latest-posts">
                  ${elementData}
                </ul>`
                const viewFragment = editor.data.processor.toView(content)
                const modelFragment = editor.data.toModel(viewFragment)
                editor.model.insertContent(modelFragment)
                dropdown.isOpen = false
              })
            }
          }
        })
      }

      dropdown.on('execute', async(e) => {
        app.$bvModal.show('wsywyg-popularpost')
        execute(num)
        num++
      })

      return dropdown
    })
  }
}
